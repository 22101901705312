<script>
import Swal from "sweetalert2";

export default {
  components: {},
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "ghost",
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {},
  methods: {
    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          await this.callback();
          Swal.fire("Deleted!", "The record has been deleted.", "success");
        }
      });
    },
  },
};
</script>

<template>
  <b-button type="button" :size="size" :variant="variant" @click="confirm">
    <span v-if="!icon">Delete</span>
    <i v-if="icon" class="mdi mdi-trash-can"></i>
  </b-button>
</template>
