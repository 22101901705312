<script>
import { required, decimal } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";
import BEditableTable from "bootstrap-vue-editable-table";
import Multiselect from "vue-multiselect";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ConfirmDelete from "@/components/confirm-delete";
import appConfig from "@/app.config";
import { payrollExtraMethods } from "../../../state/helpers";
import { mapGetters } from "vuex";

/**
 * Payroll component
 */
export default {
  page: {
    title: "Payroll",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    FormWizard,
    TabContent,
    BEditableTable,
    ConfirmDelete,
  },
  data() {
    return {
      title: "Payroll",
      activeTab: "extras",
      items: [
        {
          text: "HR",
          href: "/",
        },
        {
          text: "Payroll",
          active: true,
        },
      ],
      all_departments: true,
      selected_month: "october",
      selected_departments: [],
      earnings_fields: [
        {
          key: "name",
          label: "Employee",
          editable: false,
        },
        {
          key: "base_salary",
          label: "Salary",
          editable: false,
        },
        {
          key: "extra_hours",
          label: "Extra Hrs Amount",
          type: "number",
          min: "0",
          editable: true,
        },
        {
          key: "off_days",
          label: "Off Days Amount",
          type: "number",
          min: "0",
          editable: true,
        },
        {
          key: "correction_amount",
          label: "Correction Amount",
          type: "number",
          min: "0",
          editable: true,
        },
        {
          key: "active",
          label: "Active",
          type: "checkbox",
          editable: true,
        },
      ],
      deductions_fields: [
        {
          key: "name",
          label: "Employee",
          editable: false,
        },
        {
          key: "late_attendance",
          label: "Late Attendance",
          type: "number",
          min: "0",
          editable: true,
        },
        {
          key: "early_leave",
          label: "Early Leave",
          type: "number",
          min: "0",
          editable: true,
        },
        {
          key: "absent_days",
          label: "Absent Days",
          type: "number",
          min: "0",
          editable: true,
        },
        {
          key: "missing_hours",
          label: "Missing Hours",
          type: "number",
          min: "0",
          editable: true,
        },
        {
          key: "deduction_correction_amount",
          label: "Correction Amount",
          type: "number",
          min: "0",
          editable: true,
        },
        {
          key: "active",
          label: "Active",
          type: "checkbox",
          editable: true,
        },
      ],
      review_fields: [
        {
          key: "name",
          label: "Employee",
          editable: false,
        },
        {
          key: "base_salary",
          label: "Base Salary",
          editable: false,
        },
        {
          key: "total_earnings",
          label: "Total Earnings",
          editable: false,
        },
        {
          key: "total_deductions",
          label: "Total Deductions",
          editable: false,
        },
        {
          key: "net_pay",
          label: "Net Pay",
          editable: false,
        },
      ],
      payroll_extras_fields: [
        {
          key: "type",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "amount",
          sortable: true,
        },
        {
          key: "action",
        },
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["name", "type", "amount"],
      sortBy: "name",
      sortDesc: false,
      current_payroll_extra: {},
      submitted: false,
      staff: [
        {
          id: 1,
          name: "Edward Chambers",
          base_salary: 11056,
          extra_hours: 1396,
          off_days: 0,
          correction_amount: 0,
          late_attendance: 0,
          early_leave: 0,
          absent_days: 0,
          missing_hours: 0,
          deduction_correction_amount: 0,
          active: true,
        },
        {
          id: 2,
          name: "Betty Black",
          base_salary: 9283,
          extra_hours: 244,
          off_days: 0,
          correction_amount: 0,
          late_attendance: 0,
          early_leave: 0,
          absent_days: 0,
          missing_hours: 0,
          deduction_correction_amount: 0,
          active: true,
        },
        {
          id: 3,
          name: "Ellen Bridges",
          base_salary: 14038,
          extra_hours: 60,
          off_days: 0,
          correction_amount: 0,
          late_attendance: 0,
          early_leave: 0,
          absent_days: 0,
          missing_hours: 0,
          deduction_correction_amount: 0,
          active: true,
        },
        {
          id: 4,
          name: "Connor Castro",
          base_salary: 12885,
          extra_hours: 108,
          off_days: 0,
          correction_amount: 0,
          late_attendance: 0,
          early_leave: 0,
          absent_days: 0,
          missing_hours: 0,
          deduction_correction_amount: 0,
          active: true,
        },
      ],
    };
  },
  validations: {
    current_payroll_extra: {
      name: { required },
      type: { required },
      code: { required },
      amount: { required, decimal },
    },
  },
  methods: {
    ...payrollExtraMethods,
    toggleModal(item = { rules: [] }) {
      this.current_payroll_extra = { ...item };
      this.$bvModal.show("payroll-extra-modal");
    },
    async updatePayrollExtra(e) {
      e.preventDefault();
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      await this.createPayrollExtra(this.current_payroll_extra);
      this.$bvModal.hide("payroll-extra-modal");
    },
    async onDeletePayrollExtra(item) {
      return await this.deletePayrollExtra(item.id);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    ...mapGetters("payrollExtras", ["payroll_extras"]),
    /**
     * Total no. of records
     */
    rows() {
      return this.payroll_extras.length;
    },
    formattedStaff: {
      get() {
        console.log("formattedStaff", this.staff);
        if (!this.staff) return [];
        return this.staff.map((item) => {
          item.editable = item.editable && item.active;
          item._rowVariant = item.active ? "" : "disabled";
          return item;
        });
      },
      set(newValue) {
        console.log("Set");
        this.staff = newValue;
      },
    },
  },
  created() {
    this.getPayrollExtras();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-row>
      <b-col>
        <b-card no-body>
          <b-tabs pills card justified>
            <b-tab title="Payroll" :active="activeTab === 'payroll'">
              <form-wizard color="#556ee6">
                <tab-content icon="mdi mdi-filter-outline" title="Select">
                  <b-row>
                    <b-col>
                      <b-form-group label="Select departments">
                        <b-form-checkbox class="mb-2" v-model="all_departments">
                          All
                        </b-form-checkbox>
                        <multiselect
                          v-if="!all_departments"
                          v-model="selected_departments"
                          :options="[
                            'Software Engineering',
                            'Human Resources',
                            'Sales',
                          ]"
                          :multiple="true"
                        ></multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Select one of the below months"
                        v-slot="{ selectMonth }"
                      >
                        <b-form-radio
                          class="mb-2"
                          v-model="selected_month"
                          :aria-describedby="selectMonth"
                          name="selected_month"
                          value="august"
                        >
                          August 2022
                        </b-form-radio>
                        <b-form-radio
                          class="mb-2"
                          v-model="selected_month"
                          :aria-describedby="selectMonth"
                          name="selected_month"
                          value="september"
                        >
                          September 2022
                        </b-form-radio>
                        <b-form-radio
                          class="mb-2"
                          v-model="selected_month"
                          :aria-describedby="selectMonth"
                          name="selected_month"
                          value="october"
                        >
                          October 2022
                        </b-form-radio>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </tab-content>
                <tab-content
                  icon="mdi mdi-pencil-plus-outline"
                  title="Earnings"
                >
                  <b-editable-table
                    bordered
                    class="text-center"
                    v-model="formattedStaff"
                    :fields="earnings_fields"
                  >
                    <template #cell(active)="data">
                      <span v-if="data.value">Yes</span>
                      <span v-else>No</span>
                    </template>
                  </b-editable-table>
                </tab-content>
                <tab-content
                  icon="mdi mdi-pencil-minus-outline"
                  title="Deductions"
                >
                  <b-editable-table
                    bordered
                    class="text-center"
                    v-model="formattedStaff"
                    :fields="deductions_fields"
                  >
                    <template #cell(active)="data">
                      <span v-if="data.value">Yes</span>
                      <span v-else>No</span>
                    </template>
                  </b-editable-table>
                </tab-content>
                <tab-content
                  icon="mdi mdi-check-outline"
                  title="Review &amp; Submit"
                >
                  <b-table
                    bordered
                    class="text-center"
                    :items="formattedStaff.filter((s) => s.active)"
                    :fields="review_fields"
                  >
                    <template #cell(total_earnings)="row">
                      {{
                        parseFloat(row.item.extra_hours) +
                        parseFloat(row.item.off_days) +
                        parseFloat(row.item.correction_amount)
                      }}
                    </template>
                    <template #cell(total_deductions)="row">
                      {{
                        parseFloat(row.item.late_attendance) +
                        parseFloat(row.item.early_leave) +
                        parseFloat(row.item.absent_days) +
                        parseFloat(row.item.missing_hours) +
                        parseFloat(row.item.deduction_correction_amount)
                      }}
                    </template>
                    <template #cell(net_pay)="row">
                      {{
                        parseFloat(row.item.base_salary) +
                        parseFloat(row.item.extra_hours) +
                        parseFloat(row.item.off_days) +
                        parseFloat(row.item.correction_amount) -
                        (parseFloat(row.item.late_attendance) +
                          parseFloat(row.item.early_leave) +
                          parseFloat(row.item.absent_days) +
                          parseFloat(row.item.missing_hours) +
                          parseFloat(row.item.deduction_correction_amount))
                      }}
                    </template>
                  </b-table>
                </tab-content>
                <tab-content icon="mdi mdi-eye-outline" title="Overview">
                </tab-content>
              </form-wizard>
            </b-tab>
            <b-tab title="Payroll Extras" :active="activeTab === 'extras'">
              <b-row>
                <b-col md="6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </b-col>
                <!-- Search -->
                <b-col md="6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                  >
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                    <b-button
                      variant="success"
                      size="sm"
                      class="float-right mx-2 mb-2 spinner"
                      @click="toggleModal()"
                    >
                      <i class="mdi mdi-plus"></i>
                    </b-button>
                  </div>
                </b-col>
                <!-- End search -->
              </b-row>
              <b-table
                hover
                :items="payroll_extras"
                :fields="payroll_extras_fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(type)="row">
                  {{ $t(`payroll_extra.types.${row.value}`) }}
                </template>
                <template #cell(action)="row">
                  <b-button-group size="sm">
                    <b-button variant="primary" @click="toggleModal(row.item)">
                      <i class="mdi mdi-pencil"></i>
                    </b-button>
                    <confirm-delete
                      icon
                      variant="danger"
                      :callback="
                        async () => await onDeletePayrollExtra(row.item)
                      "
                    />
                  </b-button-group>
                </template>
              </b-table>
              <b-row>
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="payroll-extra-modal"
      size="lg"
      centered
      :title="`${current_payroll_extra.id ? 'edit' : 'add'} payroll extra`"
      title-class="text-capitalize"
      hide-footer
    >
      <form
        class="needs-validation form-horizontal"
        role="form"
        @submit.prevent="updatePayrollExtra"
      >
        <b-row>
          <b-col md="6">
            <b-form-group label="Name">
              <b-form-input
                type="text"
                name="name"
                v-model="current_payroll_extra.name"
                :class="{
                  'is-invalid':
                    submitted && $v.current_payroll_extra.name.$error,
                }"
              />
              <b-form-feedback>
                <span v-if="!$v.current_payroll_extra.name.required"
                  >This value is required.</span
                >
              </b-form-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Type">
              <b-form-select
                name="type"
                v-model="current_payroll_extra.type"
                :class="{
                  'is-invalid':
                    submitted && $v.current_payroll_extra.type.$error,
                }"
              >
                <b-form-select-option value="fixed_allowance">{{
                  $t("payroll_extra.types.fixed_allowance")
                }}</b-form-select-option>
                <b-form-select-option value="percentage_allowance">{{
                  $t("payroll_extra.types.percentage_allowance")
                }}</b-form-select-option>
                <b-form-select-option value="percentage_deduction">{{
                  $t("payroll_extra.types.percentage_deduction")
                }}</b-form-select-option>
                <b-form-select-option value="fixed_deduction">{{
                  $t("payroll_extra.types.fixed_deduction")
                }}</b-form-select-option>
              </b-form-select>
              <b-form-feedback>
                <span v-if="!$v.current_payroll_extra.type.required"
                  >This value is required.</span
                >
              </b-form-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Code">
              <b-form-input
                type="text"
                name="code"
                v-model="current_payroll_extra.code"
                :class="{
                  'is-invalid':
                    submitted && $v.current_payroll_extra.code.$error,
                }"
              />
              <b-form-feedback>
                <span v-if="!$v.current_payroll_extra.code.required"
                  >This value is required.</span
                >
              </b-form-feedback>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Amount">
              <b-form-input
                type="number"
                min="1"
                step="any"
                name="amount"
                v-model="current_payroll_extra.amount"
                :class="{
                  'is-invalid':
                    submitted && $v.current_payroll_extra.amount.$error,
                }"
              />
              <b-form-feedback>
                <span v-if="!$v.current_payroll_extra.amount.required"
                  >This value is required.</span
                >
              </b-form-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-form-group label-cols-lg="4" label="Note" label-for="note">
          <b-form-textarea name="note" />
        </b-form-group> -->
        <div class="text-right">
          <b-button type="submit" variant="primary">Submit</b-button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>